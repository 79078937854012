var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: {
            width: 1000,
            title: "自定义朝代列表",
            "ok-text": "确认",
            "cancel-text": "取消"
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [_c("div")]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.showPopup,
            callback: function($$v) {
              _vm.showPopup = $$v
            },
            expression: "showPopup"
          }
        },
        [
          _c("div", { staticClass: "popup-content" }, [
            _c("div", { staticClass: "w-100-w flex-center-center" }, [
              _c(
                "div",
                { staticClass: "search-box mb-20" },
                [
                  _c("a-input", {
                    staticClass: "w-200",
                    attrs: {
                      allowClear: "",
                      placeholder: "搜索自定义朝代名称"
                    },
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.getCustomDynastyNameList($event)
                      }
                    },
                    model: {
                      value: _vm.params.customDynastyName,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "customDynastyName", $$v)
                      },
                      expression: "params.customDynastyName"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "ml-20" },
                [
                  _c(
                    "a-button",
                    {
                      on: { click: _vm.handleShowAddOrEditorCustomDynastyName }
                    },
                    [_vm._v("新增自定义朝代")]
                  )
                ],
                1
              )
            ]),
            _vm.customDynastyNameList && _vm.customDynastyNameList.length
              ? _c(
                  "div",
                  { staticClass: "custom-dynasty-list" },
                  _vm._l(_vm.customDynastyNameList, function(item) {
                    return _c(
                      "a-tag",
                      {
                        key: item.id,
                        staticClass: "custom-dynasty-item cur-pot",
                        attrs: { color: "green" },
                        on: {
                          click: function($event) {
                            return _vm.handleShowAddOrEditorCustomDynastyName(
                              item
                            )
                          }
                        }
                      },
                      [
                        _vm._v(" " + _vm._s(item.dynastyName) + " "),
                        _c("a-icon", {
                          staticClass: "ml-20",
                          attrs: { type: "edit" }
                        })
                      ],
                      1
                    )
                  }),
                  1
                )
              : _c(
                  "div",
                  {
                    staticClass:
                      "w-100-w flex-center-center font-weight-bold mt-20 color-gray"
                  },
                  [_vm._v("暂无数据")]
                )
          ])
        ]
      ),
      _c("AddOrEditorCustomDynastyNameListPopup", {
        ref: "addOrEditorCustomDynastyNameListPopupEl",
        on: { editorSuccess: _vm.getCustomDynastyNameList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }