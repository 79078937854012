var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: {
            width: 700,
            title: "大币种开放编辑",
            "ok-text": "确认",
            "cancel-text": "取消"
          },
          on: {
            cancel: function($event) {
              _vm.show = false
            },
            ok: _vm.hideModal
          },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c(
            "div",
            { staticClass: "box-flex" },
            [
              _c("span", [_vm._v("是否将大币种：" + _vm._s(_vm.coinName))]),
              _c(
                "a-select",
                {
                  staticStyle: { width: "350px", margin: "5px" },
                  attrs: { allowClear: "", placeholder: "选择开放的状态" },
                  model: {
                    value: _vm.openStatus,
                    callback: function($$v) {
                      _vm.openStatus = $$v
                    },
                    expression: "openStatus"
                  }
                },
                _vm._l(_vm.openStatusList, function(item) {
                  return _c(
                    "a-select-option",
                    { key: item.value, attrs: { value: item.value } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "box-flex mt-20" },
            [
              _c("span", [_vm._v("选择自定义朝代分类：")]),
              _c(
                "a-select",
                {
                  staticStyle: { width: "350px", margin: "5px" },
                  attrs: {
                    "filter-option": _vm.untils.filterOption,
                    showSearch: "",
                    placeholder: "请选择自定义朝代",
                    allowClear: ""
                  },
                  on: { search: _vm.handleSearchTypeCategory },
                  model: {
                    value: _vm.customDynastyId,
                    callback: function($$v) {
                      _vm.customDynastyId = $$v
                    },
                    expression: "customDynastyId"
                  }
                },
                _vm._l(_vm.customDynastyNameList, function(item) {
                  return _c(
                    "a-select-option",
                    { key: item.id, attrs: { value: item.id } },
                    [_vm._v(_vm._s(item.dynastyName))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "box-flex mt-20" },
            [
              _c("span", [_vm._v("排序：")]),
              _c("a-input", {
                staticClass: "w-200",
                attrs: { allowClear: "", type: "number", placeholder: "排序" },
                model: {
                  value: _vm.coinSort,
                  callback: function($$v) {
                    _vm.coinSort = $$v
                  },
                  expression: "coinSort"
                }
              }),
              _c("span", { staticClass: "ml-20 color-red" }, [
                _vm._v("tips：越小越靠前")
              ])
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }