var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("div", { staticClass: "search-attr" }, [
        _c(
          "div",
          [
            _c("a-input", {
              staticStyle: { width: "150px", margin: "5px" },
              attrs: {
                allowClear: "",
                size: "small",
                placeholder: "大币种名称或ID"
              },
              model: {
                value: _vm.searchParams.coinName,
                callback: function($$v) {
                  _vm.$set(_vm.searchParams, "coinName", $$v)
                },
                expression: "searchParams.coinName"
              }
            }),
            _c(
              "a-select",
              {
                staticStyle: { width: "150px", margin: "5px" },
                attrs: {
                  allowClear: "",
                  size: "small",
                  "filter-option": _vm.untils.filterOption,
                  showSearch: "",
                  placeholder: "请选择类型"
                },
                model: {
                  value: _vm.searchParams.categoryId,
                  callback: function($$v) {
                    _vm.$set(_vm.searchParams, "categoryId", $$v)
                  },
                  expression: "searchParams.categoryId"
                }
              },
              _vm._l(_vm.currency_type_list, function(item) {
                return _c(
                  "a-select-option",
                  { key: item.id, attrs: { value: item.id } },
                  [_vm._v(_vm._s(item.coinCategoryName))]
                )
              }),
              1
            ),
            _vm.searchParams.categoryId && _vm.searchParams.categoryId !== 5
              ? _c(
                  "a-select",
                  {
                    staticStyle: { width: "150px", margin: "5px" },
                    attrs: {
                      allowClear: "",
                      size: "small",
                      "filter-option": _vm.untils.filterOption,
                      showSearch: "",
                      placeholder: "请选择朝代"
                    },
                    model: {
                      value: _vm.searchParams.dynastyId,
                      callback: function($$v) {
                        _vm.$set(_vm.searchParams, "dynastyId", $$v)
                      },
                      expression: "searchParams.dynastyId"
                    }
                  },
                  _vm._l(_vm.search_dynasty_list, function(item) {
                    return _c(
                      "a-select-option",
                      { key: item.id, attrs: { value: item.id } },
                      [_vm._v(_vm._s(item.dynastyName))]
                    )
                  }),
                  1
                )
              : _vm.searchParams.categoryId
              ? _c(
                  "a-select",
                  {
                    staticStyle: { width: "150px", margin: "5px" },
                    attrs: {
                      allowClear: "",
                      size: "small",
                      "filter-option": _vm.untils.filterOption,
                      showSearch: "",
                      placeholder: "请选择分类"
                    },
                    model: {
                      value: _vm.searchParams.dynastyClassifyId,
                      callback: function($$v) {
                        _vm.$set(_vm.searchParams, "dynastyClassifyId", $$v)
                      },
                      expression: "searchParams.dynastyClassifyId"
                    }
                  },
                  _vm._l(_vm.search_classify_list, function(item) {
                    return _c(
                      "a-select-option",
                      { key: item.id, attrs: { value: item.id } },
                      [_vm._v(_vm._s(item.dynastyName))]
                    )
                  }),
                  1
                )
              : _vm._e(),
            _c(
              "a-select",
              {
                staticStyle: { width: "150px", margin: "5px" },
                attrs: {
                  allowClear: "",
                  size: "small",
                  "filter-option": _vm.untils.filterOption,
                  showSearch: "",
                  placeholder: "请搜索皇帝"
                },
                on: { search: _vm.getKingListData },
                model: {
                  value: _vm.searchParams.dynastyRulerId,
                  callback: function($$v) {
                    _vm.$set(_vm.searchParams, "dynastyRulerId", $$v)
                  },
                  expression: "searchParams.dynastyRulerId"
                }
              },
              _vm._l(_vm.kingListData, function(item) {
                return _c(
                  "a-select-option",
                  { key: item.id, attrs: { value: item.id } },
                  [_vm._v(_vm._s(item.rulerName))]
                )
              }),
              1
            ),
            _c(
              "a-select",
              {
                staticStyle: { width: "200px", margin: "5px" },
                attrs: {
                  allowClear: "",
                  size: "small",
                  "filter-option": _vm.untils.filterOption,
                  showSearch: "",
                  placeholder: "请选择对版开放状态"
                },
                model: {
                  value: _vm.searchParams.openStatus,
                  callback: function($$v) {
                    _vm.$set(_vm.searchParams, "openStatus", $$v)
                  },
                  expression: "searchParams.openStatus"
                }
              },
              _vm._l(_vm.openStatusList, function(item) {
                return _c(
                  "a-select-option",
                  { key: item.value, attrs: { value: item.value } },
                  [_vm._v(_vm._s(item.name))]
                )
              }),
              1
            ),
            _c("a-input", {
              staticStyle: { width: "150px", margin: "5px" },
              attrs: { allowClear: "", size: "small", placeholder: "标签" },
              model: {
                value: _vm.searchParams.tags,
                callback: function($$v) {
                  _vm.$set(_vm.searchParams, "tags", $$v)
                },
                expression: "searchParams.tags"
              }
            }),
            _c(
              "a-button",
              {
                staticStyle: { "margin-left": "10px" },
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.search }
              },
              [_vm._v("搜索")]
            ),
            _c(
              "a-checkbox",
              {
                staticStyle: { "margin-left": "20px" },
                on: { change: _vm.onChange }
              },
              [_vm._v(" 查看已生产二维码的币种 ")]
            )
          ],
          1
        ),
        _c("div", [
          _c(
            "div",
            [
              _c(
                "a-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.openModal({})
                    }
                  }
                },
                [_vm._v("新增大币种")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-20" },
            [
              _c(
                "a-button",
                {
                  attrs: { size: "small" },
                  on: { click: _vm.handleShowDuiBanCustomCategoryList }
                },
                [_vm._v("对版自定义朝代分类列表")]
              )
            ],
            1
          )
        ])
      ]),
      _c(
        "div",
        {
          staticClass: "table-content",
          staticStyle: { "padding-top": "20px" }
        },
        [
          _c("a-table", {
            attrs: {
              loading: _vm.loading,
              columns: _vm.columns,
              pagination: _vm.pagination,
              "data-source": _vm.data,
              rowKey: function(record) {
                return record.id
              }
            },
            on: { change: _vm.changePage },
            scopedSlots: _vm._u([
              {
                key: "Images",
                fn: function(row) {
                  return _c(
                    "div",
                    {
                      staticStyle: {
                        width: "100px",
                        height: "100px",
                        display: "flex",
                        "justify-content": "center",
                        "align-items": "center"
                      }
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          "max-width": "100px",
                          "max-height": "100px"
                        },
                        attrs: {
                          src:
                            row.coinKindIcon != null
                              ? row.coinKindIcon
                              : "http://image.douquan.com/static/product-default.png",
                          alt: ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.previewImg(row)
                          }
                        }
                      })
                    ]
                  )
                }
              },
              {
                key: "backImages",
                fn: function(val) {
                  return _c("div", {}, [
                    _c("img", {
                      staticStyle: {
                        "max-width": "100px",
                        "max-height": "100px"
                      },
                      attrs: {
                        src:
                          val != null
                            ? val
                            : "http://image.douquan.com/static/product-default.png",
                        alt: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.previewImg(val)
                        }
                      }
                    })
                  ])
                }
              },
              {
                key: "tags",
                fn: function(row) {
                  return _c(
                    "div",
                    {},
                    _vm._l(row.tagList, function(tag, key) {
                      return _c(
                        "a-tag",
                        { key: key, attrs: { color: "blue" } },
                        [_vm._v(_vm._s(tag.coinTagName))]
                      )
                    }),
                    1
                  )
                }
              },
              {
                key: "nameSetup",
                fn: function(row) {
                  return _c(
                    "div",
                    {},
                    [
                      row.coinKindIcon
                        ? _c(
                            "a-popover",
                            { attrs: { title: "" } },
                            [
                              _c("template", { slot: "content" }, [
                                _c("img", {
                                  staticStyle: { height: "100px" },
                                  attrs: { src: row.coinKindIcon, alt: "" }
                                })
                              ]),
                              _c("span", [_vm._v(_vm._s(row.coinKindName))])
                            ],
                            2
                          )
                        : _c("span", [_vm._v(_vm._s(row.coinKindName))])
                    ],
                    1
                  )
                }
              },
              {
                key: "twoSetup",
                fn: function(row) {
                  return _c("div", {}, [
                    row.kindCode
                      ? _c(
                          "span",
                          {
                            staticClass: "codeText",
                            on: {
                              click: function($event) {
                                return _vm.lookCOde(row.kindCode)
                              }
                            }
                          },
                          [_vm._v(" 查看二维码 ")]
                        )
                      : _c(
                          "span",
                          {
                            staticClass: "codeText",
                            on: {
                              click: function($event) {
                                return _vm.makeCode(row)
                              }
                            }
                          },
                          [_vm._v("生成二维码")]
                        )
                  ])
                }
              },
              {
                key: "dynastyTypeSlot",
                fn: function(row) {
                  return _c("div", {}, [
                    row.dynastyClassifyList
                      ? _c(
                          "div",
                          { staticClass: "flex-start-center flex-wrap" },
                          _vm._l(row.dynastyClassifyList, function(
                            item,
                            index
                          ) {
                            return _c("div", { key: item.id }, [
                              _vm._v(" " + _vm._s(item.dynastyName) + " "),
                              index !== row.dynastyClassifyList.length - 1
                                ? _c("span", [_vm._v(",")])
                                : _vm._e()
                            ])
                          }),
                          0
                        )
                      : row.dynastyId
                      ? _c("div", [_vm._v(_vm._s(row.dynastyName))])
                      : _vm._e()
                  ])
                }
              },
              {
                key: "setup",
                fn: function(row) {
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "a-button",
                        {
                          staticStyle: { "margin-right": "5px" },
                          attrs: { size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.openModal(row)
                            }
                          }
                        },
                        [_vm._v("编辑")]
                      ),
                      _c(
                        "a-button",
                        {
                          staticStyle: { "margin-right": "5px" },
                          attrs: { size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.handleShowChangeOpenCoin(row)
                            }
                          }
                        },
                        [_vm._v("开放对版编辑")]
                      ),
                      _c(
                        "a-popconfirm",
                        {
                          attrs: {
                            title: "Are you sure delete this item?",
                            "ok-text": "Yes",
                            "cancel-text": "No"
                          },
                          on: {
                            confirm: function($event) {
                              return _vm.remove(row.id)
                            }
                          }
                        },
                        [
                          _c(
                            "a-button",
                            {
                              staticStyle: { "margin-left": "5px" },
                              attrs: { size: "small" }
                            },
                            [_vm._v("删除")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }
              },
              {
                key: "openStatus",
                fn: function(row) {
                  return _c("div", {}, [
                    row.duibanCoin
                      ? _c(
                          "div",
                          {
                            class: _vm.openStatusClassType.get(
                              row.duibanCoin.openStatus
                            )
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.openStatusText.get(
                                  row.duibanCoin.openStatus
                                )
                              )
                            )
                          ]
                        )
                      : _c("div", { class: _vm.openStatusClassType.get(-1) }, [
                          _vm._v("未开放")
                        ])
                  ])
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.innerTitle,
            "ok-text": "确认",
            "cancel-text": "取消"
          },
          on: { cancel: _vm.resetForms, ok: _vm.hideModal },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "ruleForm",
                staticStyle: { height: "500px", "overflow-y": "auto" },
                attrs: { model: _vm.forms, rules: _vm.rules }
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "钱币分类", prop: "coinCategorySid" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        "filter-option": _vm.untils.filterOption,
                        showSearch: "",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.forms.coinCategorySid,
                        callback: function($$v) {
                          _vm.$set(_vm.forms, "coinCategorySid", $$v)
                        },
                        expression: "forms.coinCategorySid"
                      }
                    },
                    _vm._l(_vm.currency_type_list, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.id, attrs: { value: item.sid } },
                        [_vm._v(_vm._s(item.coinCategoryName))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "名称", prop: "coinKindName" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入名称" },
                    model: {
                      value: _vm.forms.coinKindName,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "coinKindName", $$v)
                      },
                      expression: "forms.coinKindName"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "标签" } },
                [
                  _vm._l(_vm.forms.tagList, function(_item, _index) {
                    return _c(
                      "a-tag",
                      {
                        key: _index,
                        attrs: { closable: "" },
                        on: {
                          close: function($event) {
                            return _vm.removeTag(_index)
                          },
                          click: function($event) {
                            return _vm.confirmTag(_item, _index)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_item.coinTagName))]
                    )
                  }),
                  _c(
                    "a-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.confirmTag({})
                        }
                      }
                    },
                    [_vm._v("添加")]
                  )
                ],
                2
              ),
              _vm.forms.coinCategorySid !== "6ed643eb-8842-4b"
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "朝代", prop: "dynastyId" } },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            "filter-option": _vm.untils.filterOption,
                            showSearch: "",
                            allowClear: "",
                            placeholder: "请选择朝代"
                          },
                          on: {
                            change: _vm.changeDynasty,
                            search: function($event) {
                              return _vm.getDynastyList($event, "dynasty")
                            }
                          },
                          model: {
                            value: _vm.forms.dynastyId,
                            callback: function($$v) {
                              _vm.$set(_vm.forms, "dynastyId", $$v)
                            },
                            expression: "forms.dynastyId"
                          }
                        },
                        _vm._l(_vm.dynasty_list, function(item) {
                          return _c(
                            "a-select-option",
                            {
                              key: item.id,
                              attrs: { value: item.id, childs: item.rulerList }
                            },
                            [_vm._v(_vm._s(item.dynastyName))]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "a-form-model-item",
                    { attrs: { label: "分类" } },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            "filter-option": _vm.untils.filterOption,
                            showSearch: "",
                            allowClear: "",
                            mode: "multiple",
                            placeholder: "请选择分类"
                          },
                          on: {
                            search: function($event) {
                              return _vm.getDynastyList($event, "classify")
                            }
                          },
                          model: {
                            value: _vm.tempDynastyClassifyIdList,
                            callback: function($$v) {
                              _vm.tempDynastyClassifyIdList = $$v
                            },
                            expression: "tempDynastyClassifyIdList"
                          }
                        },
                        _vm._l(_vm.classify_list, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.id, attrs: { value: item.id } },
                            [_vm._v(_vm._s(item.dynastyName))]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
              _vm.forms.coinCategorySid !== "6ed643eb-8842-4b"
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "皇帝", prop: "dynastyRulerId" } },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            mode: "tags",
                            "filter-option": _vm.untils.filterOption,
                            showSearch: "",
                            placeholder: "请选择皇帝"
                          },
                          on: {
                            change: function($event) {
                              return _vm.changeSelect($event)
                            }
                          },
                          model: {
                            value: _vm.forms.dynastyRulerId,
                            callback: function($$v) {
                              _vm.$set(_vm.forms, "dynastyRulerId", $$v)
                            },
                            expression: "forms.dynastyRulerId"
                          }
                        },
                        _vm._l(_vm.emperor_list, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.id, attrs: { value: item.id } },
                            [_vm._v(_vm._s(item.rulerName))]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-model-item",
                { attrs: { label: "正面图片" } },
                [
                  _c(
                    "a-upload",
                    {
                      attrs: {
                        multiple: true,
                        showUploadList: false,
                        action: _vm.ip + "/file/upload",
                        "list-type": "picture-card"
                      },
                      on: { change: _vm.coinKindIconChange }
                    },
                    [
                      !_vm.forms.coinKindIcon
                        ? _c(
                            "div",
                            [
                              _c("a-icon", { attrs: { type: "plus" } }),
                              _c("div", { staticClass: "ant-upload-text" }, [
                                _vm._v("上传图片")
                              ])
                            ],
                            1
                          )
                        : _c("img", {
                            staticStyle: { width: "100%" },
                            attrs: { src: _vm.forms.coinKindIcon }
                          })
                    ]
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "反面图片" } },
                [
                  _c(
                    "a-upload",
                    {
                      attrs: {
                        multiple: true,
                        showUploadList: false,
                        action: _vm.ip + "/file/upload",
                        "list-type": "picture-card"
                      },
                      on: { change: _vm.coinKindBackIconChange }
                    },
                    [
                      !_vm.forms.coinKindBackIcon
                        ? _c(
                            "div",
                            [
                              _c("a-icon", { attrs: { type: "plus" } }),
                              _c("div", { staticClass: "ant-upload-text" }, [
                                _vm._v("上传图片")
                              ])
                            ],
                            1
                          )
                        : _c("img", {
                            staticStyle: { width: "100%" },
                            attrs: { src: _vm.forms.coinKindBackIcon }
                          })
                    ]
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "描述" } },
                [
                  _c("a-textarea", {
                    attrs: { placeholder: "请输入描述", "auto-size": "" },
                    model: {
                      value: _vm.forms.coinKindDesc,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "coinKindDesc", $$v)
                      },
                      expression: "forms.coinKindDesc"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "排序" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "排序" },
                    model: {
                      value: _vm.forms.coinKindTopSort,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "coinKindTopSort", $$v)
                      },
                      expression: "forms.coinKindTopSort"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: "新增表签",
            "ok-text": "确认",
            "cancel-text": "取消"
          },
          on: { cancel: _vm.cancelTags, ok: _vm.addTag },
          model: {
            value: _vm.tagVisible,
            callback: function($$v) {
              _vm.tagVisible = $$v
            },
            expression: "tagVisible"
          }
        },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "tagforms",
                attrs: { model: _vm.tagForms, rules: _vm.tagRules }
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "名称", prop: "coinTagName" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "名称" },
                    model: {
                      value: _vm.tagForms.coinTagName,
                      callback: function($$v) {
                        _vm.$set(_vm.tagForms, "coinTagName", $$v)
                      },
                      expression: "tagForms.coinTagName"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "标签分类", prop: "coinTagCategory" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { placeholder: "标签分类" },
                      model: {
                        value: _vm.tagForms.coinTagCategory,
                        callback: function($$v) {
                          _vm.$set(_vm.tagForms, "coinTagCategory", $$v)
                        },
                        expression: "tagForms.coinTagCategory"
                      }
                    },
                    [
                      _c("a-select-option", { attrs: { value: 1 } }, [
                        _vm._v("别名标签")
                      ]),
                      _c("a-select-option", { attrs: { value: 2 } }, [
                        _vm._v("钱币性质标签")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: "二维码预览",
            "ok-text": "下载",
            "cancel-text": "取消"
          },
          on: { cancel: _vm.cancelCodeVisible, ok: _vm.downloadCode },
          model: {
            value: _vm.codeVisible,
            callback: function($$v) {
              _vm.codeVisible = $$v
            },
            expression: "codeVisible"
          }
        },
        [
          _c("div", { staticClass: "code" }, [
            _c("img", { attrs: { src: _vm.url, alt: "" } })
          ])
        ]
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: "编辑百科",
            "ok-text": "确定",
            "cancel-text": "取消",
            maskClosable: false,
            width: 700
          },
          on: { cancel: _vm.hideWiki, ok: _vm.submitWiki },
          model: {
            value: _vm.editWikiVisible,
            callback: function($$v) {
              _vm.editWikiVisible = $$v
            },
            expression: "editWikiVisible"
          }
        },
        [
          _c(
            "div",
            { staticClass: "edit-wiki" },
            [
              _c(
                "div",
                { staticClass: "utils-box" },
                [
                  _c(
                    "a-upload",
                    {
                      staticStyle: { "margin-right": "10px" },
                      attrs: {
                        action: _vm.ip + "/file/upload",
                        multiple: true,
                        showUploadList: false
                      },
                      on: { change: _vm.handleChangeEdit }
                    },
                    [
                      _c(
                        "a-button",
                        {
                          staticClass: "util-item",
                          attrs: { type: "primary", size: "small" }
                        },
                        [_vm._v("插入图片")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("quillEditor", {
                ref: "myQuillEditor",
                staticStyle: { "min-height": "200px" },
                attrs: { options: _vm.options },
                model: {
                  value: _vm.wikiContent,
                  callback: function($$v) {
                    _vm.wikiContent = $$v
                  },
                  expression: "wikiContent"
                }
              })
            ],
            1
          )
        ]
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: "预览百科",
            "ok-text": "确定",
            "cancel-text": "取消",
            width: 700
          },
          on: { cancel: _vm.hideWikiVisible, ok: _vm.hideWikiVisible },
          model: {
            value: _vm.previewWikiVisible,
            callback: function($$v) {
              _vm.previewWikiVisible = $$v
            },
            expression: "previewWikiVisible"
          }
        },
        [
          _c("div", {
            staticClass: "preview-wiki",
            domProps: { innerHTML: _vm._s(_vm.currentViki.coinKindContent) }
          })
        ]
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: "添加超链接",
            "ok-text": "确定",
            "cancel-text": "取消"
          },
          on: { cancel: _vm.hideAddLinkModal, ok: _vm.addLink },
          model: {
            value: _vm.addLinkModal,
            callback: function($$v) {
              _vm.addLinkModal = $$v
            },
            expression: "addLinkModal"
          }
        },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "addLinkforms",
                attrs: { model: _vm.addLinkForms, rules: _vm.addLinkRules }
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "名称", prop: "name" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入名称" },
                    model: {
                      value: _vm.addLinkForms.name,
                      callback: function($$v) {
                        _vm.$set(_vm.addLinkForms, "name", $$v)
                      },
                      expression: "addLinkForms.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "链接地址", prop: "url" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入链接地址" },
                    model: {
                      value: _vm.addLinkForms.url,
                      callback: function($$v) {
                        _vm.$set(_vm.addLinkForms, "url", $$v)
                      },
                      expression: "addLinkForms.url"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("BigCoinOpenPopup", {
        ref: "bigCoinOpenPopupEl",
        on: { success: _vm.search }
      }),
      _c("CustomDynastyNameListPopup", { ref: "customDynastyNameListPopupEl" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }