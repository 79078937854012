export const openStatusList = [
    {name: '不开放', value: -1},
    {name: '开放', value: 0},
    {name: '测试', value: 1}
]

export const openStatusText = new Map([
    [-1, '未开放'],
    [0, '开放中'],
    [1, '测试中'],
])

export const openStatusClassType = new Map([
    [-1, 'color-red'],
    [0, 'color-green'],
    [1, 'color-orange'],
])