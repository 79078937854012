<template>
  <div>
    <a-modal
        :width="300"
        v-model="showPopupSon"
        :title="title"
        ok-text="确认"
        cancel-text="取消"
        @cancel="showPopupSon = false"
        @ok="handleConfirmAddOrEditor"
    >
      <div class="popup-content-son">
        <div class="box-flex">
          <span>名称：</span>
          <a-input
              allowClear
              v-model="params.dynastyName"
              class="w-200"
              placeholder='自定义朝代名称'
          ></a-input>
        </div>
        <div class="box-flex mt-20">
          <span>排序：</span>
          <a-input-number
              :min="0"
              :max="10000000000"
              :step="10"
              v-model="params.sort"
              class="w-200"
              placeholder='排序'
          />
        </div>
        <div class="color-red">tips：越小越靠前</div>
      </div>
    </a-modal>
  </div>
</template>

<script>

import {postCustomDynastyApi} from "@/views/cmsPage/currencyMannage/_apis"

export default {
  data() {
    return {
      title: '新增自定义朝代',
      showPopupSon: false,
      params: {
        dynastyName: undefined,
        sort: undefined,
        id: undefined
      },
      customId: ''
    };
  },
  methods: {
    async show(data) {
      this.resetForms()
      if (data && data.id) {
        this.customId = data.id
        this.title = '编辑自定义朝代'
        this.params.dynastyName = data.dynastyName
        this.params.sort = data.sort
        this.params.id = data.id
      }
      this.showPopupSon = true
    },
    /** 确定编辑 */
    async handleConfirmAddOrEditor() {
      this.$loading.show()
      this.params.sort = Number(this.params.sort)
      const res = await postCustomDynastyApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.success(this.customId ? '编辑成功' : '添加成功')
      this.$emit('editorSuccess')
      this.showPopupSon = false
    },
    /** 重置数据 */
    resetForms() {
      this.params = {
        dynastyName: undefined,
        sort: undefined,
        id: undefined
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.box-flex {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.box-flex::v-deep .ant-input-number-handler-wrap {
  width: 100px;
}
.popup-content-son {
  width: 100%;
}

</style>
