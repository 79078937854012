<template>
  <div>
    <a-modal
        :width="1000"
        v-model="showPopup"
        title="自定义朝代列表"
        ok-text="确认"
        cancel-text="取消"
    >
      <template #footer>
        <div></div>
      </template>
      <div class="popup-content">
        <div class="w-100-w flex-center-center">
          <div class="search-box mb-20">
            <a-input
                allowClear
                v-model="params.customDynastyName"
                class="w-200"
                @keyup.enter="getCustomDynastyNameList"
                placeholder='搜索自定义朝代名称'
            ></a-input>
          </div>
          <div class="ml-20">
            <a-button
                @click="handleShowAddOrEditorCustomDynastyName"
            >新增自定义朝代</a-button>
          </div>
        </div>
<!--    内容列表    -->
        <div class="custom-dynasty-list" v-if="customDynastyNameList && customDynastyNameList.length">
          <a-tag
              v-for="item in customDynastyNameList"
              :key="item.id"
              class="custom-dynasty-item cur-pot"
              color="green"
              @click="handleShowAddOrEditorCustomDynastyName(item)"
          >
            {{ item.dynastyName }}
            <a-icon class="ml-20" type="edit" />
          </a-tag>
        </div>
        <div v-else class="w-100-w flex-center-center font-weight-bold mt-20 color-gray">暂无数据</div>
      </div>
    </a-modal>
<!--  编辑自定义朝代  -->
    <AddOrEditorCustomDynastyNameListPopup
        ref="addOrEditorCustomDynastyNameListPopupEl"
        @editorSuccess="getCustomDynastyNameList"
    />
  </div>
</template>

<script>
import {getCustomDynastyListApi} from "@/views/cmsPage/currencyMannage/_apis"
import AddOrEditorCustomDynastyNameListPopup
  from "@/views/cmsPage/currencyMannage/_components/AddOrEditorCustomDynastyNameListPopup"

export default {
  components: {AddOrEditorCustomDynastyNameListPopup},
  data() {
    return {
      showPopup: false,
      params: {
        customDynastyName: undefined,
        pageSize: 100,
        pageNum: 1
      },
      customDynastyNameList: []
    };
  },
  methods: {
    async show() {
      this.resetForms()
      await this.getCustomDynastyNameList()
      this.showPopup = true
    },
    /** 获取自定义朝代名称 */
    async getCustomDynastyNameList() {
      this.$loading.show()
      const res = await getCustomDynastyListApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return
      this.customDynastyNameList = res.data.data.records
      console.log(this.customDynastyNameList, 'asjdlf')
    },
    /** 添加，编辑弹窗 */
    handleShowAddOrEditorCustomDynastyName(data) {
      this.$refs.addOrEditorCustomDynastyNameListPopupEl.show(data)
    },
    /** 重置数据 */
    resetForms() {
      this.params = {
        customDynastyName: undefined
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-dynasty-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
}
</style>
