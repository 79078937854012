var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: {
            width: 300,
            title: _vm.title,
            "ok-text": "确认",
            "cancel-text": "取消"
          },
          on: {
            cancel: function($event) {
              _vm.showPopupSon = false
            },
            ok: _vm.handleConfirmAddOrEditor
          },
          model: {
            value: _vm.showPopupSon,
            callback: function($$v) {
              _vm.showPopupSon = $$v
            },
            expression: "showPopupSon"
          }
        },
        [
          _c("div", { staticClass: "popup-content-son" }, [
            _c(
              "div",
              { staticClass: "box-flex" },
              [
                _c("span", [_vm._v("名称：")]),
                _c("a-input", {
                  staticClass: "w-200",
                  attrs: { allowClear: "", placeholder: "自定义朝代名称" },
                  model: {
                    value: _vm.params.dynastyName,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "dynastyName", $$v)
                    },
                    expression: "params.dynastyName"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "box-flex mt-20" },
              [
                _c("span", [_vm._v("排序：")]),
                _c("a-input-number", {
                  staticClass: "w-200",
                  attrs: {
                    min: 0,
                    max: 10000000000,
                    step: 10,
                    placeholder: "排序"
                  },
                  model: {
                    value: _vm.params.sort,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "sort", $$v)
                    },
                    expression: "params.sort"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "color-red" }, [
              _vm._v("tips：越小越靠前")
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }