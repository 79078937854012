<template>
  <div>
    <a-modal
        :width="700"
        v-model="show"
        title="大币种开放编辑"
        ok-text="确认"
        cancel-text="取消"
        @cancel="show = false"
        @ok="hideModal"
    >
      <div class="box-flex">
        <span>是否将大币种：{{ coinName }}</span>
        <a-select
            allowClear
            v-model="openStatus"
            style="width:350px;margin:5px;"
            placeholder='选择开放的状态'
        >
          <a-select-option
              v-for="item in openStatusList"
              :key="item.value"
              :value="item.value"
          >{{ item.name }}</a-select-option>
        </a-select>
      </div>
      <div class="box-flex mt-20">
        <span>选择自定义朝代分类：</span>
        <a-select
            @search="handleSearchTypeCategory"
            v-model="customDynastyId"
            :filter-option="untils.filterOption"
            showSearch
            style="width:350px;margin:5px;"
            placeholder="请选择自定义朝代"
            allowClear
        >
          <a-select-option
              v-for="item of customDynastyNameList"
              :key="item.id"
              :value="item.id"
          >{{ item.dynastyName }}</a-select-option>
        </a-select>
      </div>
      <div class="box-flex mt-20">
        <span>排序：</span>
        <a-input
            allowClear
            v-model="coinSort"
            class="w-200"
            type="number"
            placeholder='排序'
        ></a-input>
        <span class="ml-20 color-red" >tips：越小越靠前</span>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {openStatusList} from "@/views/cmsPage/currencyMannage/_data"
import untils from "@/untils"

export default {
  props: ["detail"],
  data() {
    return {
      untils,
      openStatus: undefined,
      customDynastyId: undefined,
      coinSort: undefined,
      openStatusList,
      show: false,
      coinId: '',
      coinName: '',
      customDynastyNameList: []
    };
  },
  methods: {
    async showPopup(id, name, duibanCoin) {
      this.resetForms()
      if (duibanCoin && duibanCoin.customDynastyId) {
        this.coinSort = duibanCoin.coinSort
        this.customDynastyId = duibanCoin.customDynastyId
        this.openStatus = duibanCoin.openStatus
      }
      this.coinId = id
      this.coinName = name
      await this.handleSearchTypeCategory()
      this.show = true
    },
    async hideModal() {
      const res = await this.axios(
          "/dq_admin/kind/editCoinKindOpenStatus",
          {
            params: {
              coinId: this.coinId,
              openStatus: this.openStatus,
              coinSort: this.coinSort,
              customDynastyId: this.customDynastyId,
            }
          }
      );
      if (res.status != 200) return;
      this.$message.success(res.message);
      this.show = false
      this.$emit("success");
    },
    /** 搜索自定义朝代分类 */
    async handleSearchTypeCategory(e) {
      const res = await this.axios('/dq_admin/duibanSupportCustomDynasty/list', {params: {customDynastyName: e, pageSize: 100, pageNum: 1}})
      if (res.status !== '200') return
      this.customDynastyNameList = res.data.data.records
    },
    resetForms() {
      this.openStatus = undefined
      this.customDynastyId = undefined
      this.coinSort = undefined
    }
  },
};
</script>

<style lang="scss" scoped>
.box-flex {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  span {
    margin-right: 20px;
  }
}
</style>
