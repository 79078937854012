<template>
  <div class="content">
    <div class="search-attr">
      <div>
        <a-input
            allowClear
          v-model="searchParams.coinName"
          size="small"
          style="width: 150px; margin: 5px"
          placeholder="大币种名称或ID"
        ></a-input>
        <a-select
          allowClear
          style="width: 150px; margin: 5px"
          size="small"
          :filter-option="untils.filterOption"
          showSearch
          v-model="searchParams.categoryId"
          placeholder="请选择类型"
        >
          <a-select-option
            v-for="item of currency_type_list"
            :key="item.id"
            :value="item.id"
            >{{ item.coinCategoryName }}</a-select-option>
        </a-select>
        <a-select
          v-if="searchParams.categoryId && searchParams.categoryId !== 5"
          allowClear
          style="width: 150px; margin: 5px"
          size="small"
          :filter-option="untils.filterOption"
          showSearch
          v-model="searchParams.dynastyId"
          placeholder="请选择朝代"
        >
          <a-select-option
            v-for="item of search_dynasty_list"
            :key="item.id"
            :value="item.id"
            >{{ item.dynastyName }}</a-select-option>
        </a-select>
        <a-select
            v-else-if="searchParams.categoryId"
            allowClear
            style="width: 150px; margin: 5px"
            size="small"
            :filter-option="untils.filterOption"
            showSearch
            v-model="searchParams.dynastyClassifyId"
            placeholder="请选择分类"
        >
          <a-select-option
              v-for="item of search_classify_list"
              :key="item.id"
              :value="item.id"
          >{{ item.dynastyName }}</a-select-option>
        </a-select>
        <a-select
            allowClear
            style="width: 150px; margin: 5px"
            size="small"
            :filter-option="untils.filterOption"
            showSearch
            v-model="searchParams.dynastyRulerId"
            @search="getKingListData"
            placeholder="请搜索皇帝"
        >
          <a-select-option
              v-for="item of kingListData"
              :key="item.id"
              :value="item.id"
          >{{ item.rulerName }}</a-select-option>
        </a-select>
        <a-select
            allowClear
            style="width: 200px; margin: 5px"
            size="small"
            :filter-option="untils.filterOption"
            showSearch
            v-model="searchParams.openStatus"
            placeholder="请选择对版开放状态"
        >
          <a-select-option
              v-for="item of openStatusList"
              :key="item.value"
              :value="item.value"
          >{{ item.name }}</a-select-option
          >
        </a-select>
        <a-input
            allowClear
          v-model="searchParams.tags"
          size="small"
          style="width: 150px; margin: 5px"
          placeholder="标签"
        ></a-input>
        <a-button @click="search" size="small" type="primary" style="margin-left: 10px"
          >搜索</a-button
        >
        <a-checkbox @change="onChange" style="margin-left: 20px">
          查看已生产二维码的币种
        </a-checkbox>
      </div>
      <div>
        <div>
          <a-button size="small" @click="openModal({})">新增大币种</a-button>
        </div>
        <div class="mt-20">
          <a-button size="small" @click="handleShowDuiBanCustomCategoryList">对版自定义朝代分类列表</a-button>
        </div>
      </div>
    </div>
    <div class="table-content" style="padding-top: 20px">
      <a-table
        @change="changePage"
        :loading="loading"
        :columns="columns"
        :pagination="pagination"
        :data-source="data"
        :rowKey="(record) => record.id"
      >
        <div slot="Images" slot-scope="row" style="width:100px;height:100px;display:flex;justify-content:center;align-items:center">
            <img @click="previewImg(row)" style="max-width:100px;max-height:100px;" :src="row.coinKindIcon!=null ? row.coinKindIcon : 'http://image.douquan.com/static/product-default.png'" alt="">
        </div>
        <div slot="backImages" slot-scope="val">
          <img @click="previewImg(val)" style="max-width:100px;max-height:100px;" :src="val!=null ? val : 'http://image.douquan.com/static/product-default.png'" alt="">
        </div>
        <div slot="tags" slot-scope="row">
          <a-tag v-for="(tag, key) in row.tagList" :key="key" color="blue">{{
            tag.coinTagName
          }}</a-tag>
        </div>
        <div slot="nameSetup" slot-scope="row">
          <a-popover title="" v-if="row.coinKindIcon">
            <template slot="content">
              <img :src="row.coinKindIcon" alt="" style="height: 100px" />
            </template>
            <span>{{ row.coinKindName }}</span>
          </a-popover>
          <span v-else>{{ row.coinKindName }}</span>
        </div>
        <!-- <div slot="coinKindContent" slot-scope="row">
          <span @click="editWikiClick(row)" class="codeText">wiki</span>
          <span
            @click="previewWikiClick(row)"
            class="codeText"
            v-if="row.coinKindContent"
          >
            预览
          </span>
        </div> -->
        <div slot="twoSetup" slot-scope="row">
          <span
            @click="lookCOde(row.kindCode)"
            class="codeText"
            v-if="row.kindCode"
          >
            查看二维码
          </span>
          <span @click="makeCode(row)" class="codeText" v-else>生成二维码</span>
        </div>
<!--    分类类型    -->
        <div slot="dynastyTypeSlot" slot-scope="row">
          <div v-if="row.dynastyClassifyList" class="flex-start-center flex-wrap">
            <div v-for="(item, index) in row.dynastyClassifyList" :key="item.id">
              {{ item.dynastyName }}
              <span v-if="index !== row.dynastyClassifyList.length - 1">,</span>
            </div>
          </div>
          <div v-else-if="row.dynastyId">{{ row.dynastyName }}</div>
        </div>
        <div slot="setup" slot-scope="row">
          <a-button size="small" @click="openModal(row)" style="margin-right: 5px">编辑</a-button>
          <a-button size="small" @click="handleShowChangeOpenCoin(row)" style="margin-right: 5px">开放对版编辑</a-button>
          <a-popconfirm
            title="Are you sure delete this item?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="remove(row.id)"
          >
            <a-button style="margin-left: 5px" size="small">删除</a-button>
          </a-popconfirm>
        </div>
        <div slot="openStatus" slot-scope="row">
          <div v-if="row.duibanCoin" :class="openStatusClassType.get(row.duibanCoin.openStatus)">{{ openStatusText.get(row.duibanCoin.openStatus) }}</div>
          <div v-else :class="openStatusClassType.get(-1)">未开放</div>
        </div>
      </a-table>
    </div>

    <a-modal
      v-model="visible"
      :title="innerTitle"
      ok-text="确认"
      cancel-text="取消"
      @cancel="resetForms"
      @ok="hideModal"
    >
      <a-form-model
        style="height: 500px; overflow-y: auto"
        ref="ruleForm"
        :model="forms"
        :rules="rules"
        v-bind="layout"
      >
        <a-form-model-item label="钱币分类" prop="coinCategorySid">
          <a-select
            :filter-option="untils.filterOption"
            showSearch
            v-model="forms.coinCategorySid"
            placeholder="请选择"
          >
            <a-select-option
              v-for="item of currency_type_list"
              :key="item.id"
              :value="item.sid"
              >{{ item.coinCategoryName }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="名称" prop="coinKindName">
          <a-input placeholder="请输入名称" v-model="forms.coinKindName" />
        </a-form-model-item>

        <a-form-model-item label="标签">
          <a-tag
            closable
            @close="removeTag(_index)"
            v-for="(_item, _index) in forms.tagList"
            :key="_index"
            @click="confirmTag(_item, _index)"
            >{{ _item.coinTagName }}</a-tag
          >
          <a-button @click="confirmTag({})" size="small">添加</a-button>
        </a-form-model-item>

        <a-form-model-item label="朝代" prop="dynastyId" v-if="forms.coinCategorySid !== '6ed643eb-8842-4b'">
          <a-select
            :filter-option="untils.filterOption"
            showSearch
            allowClear
            v-model="forms.dynastyId"
            placeholder="请选择朝代"
            @change="changeDynasty"
            @search="getDynastyList($event, 'dynasty')"
          >
            <a-select-option
              v-for="item of dynasty_list"
              :key="item.id"
              :value="item.id"
              :childs="item.rulerList"
              >{{ item.dynastyName }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="分类" v-else>
          <a-select
              :filter-option="untils.filterOption"
              showSearch
              allowClear
              mode="multiple"
              v-model="tempDynastyClassifyIdList"
              placeholder="请选择分类"
              @search="getDynastyList($event, 'classify')"
          >
            <a-select-option
                v-for="item of classify_list"
                :key="item.id"
                :value="item.id"
            >{{ item.dynastyName }}</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="皇帝" prop="dynastyRulerId" v-if="forms.coinCategorySid !== '6ed643eb-8842-4b'">
          <a-select
            mode="tags"
            :filter-option="untils.filterOption"
            showSearch
            placeholder="请选择皇帝"
            v-model="forms.dynastyRulerId"
            @change="changeSelect($event)"
          >
            <a-select-option
              v-for="item of emperor_list"
              :key="item.id"
              :value="item.id"
              >{{ item.rulerName }}</a-select-option
            >
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="正面图片">
          <a-upload
            :multiple="true"
            :showUploadList="false"
            :action="ip + '/file/upload'"
            list-type="picture-card"
            @change="coinKindIconChange"
          >
            <div v-if="!forms.coinKindIcon">
              <a-icon type="plus" />
              <div class="ant-upload-text">上传图片</div>
            </div>
            <img v-else :src="forms.coinKindIcon" style="width: 100%" />
          </a-upload>
        </a-form-model-item>

        <a-form-model-item label="反面图片">
          
          <a-upload
            :multiple="true"
            :showUploadList="false"
            :action="ip + '/file/upload'"
            list-type="picture-card"
            @change="coinKindBackIconChange"
          >
            <div v-if="!forms.coinKindBackIcon">
              <a-icon type="plus" />
              <div class="ant-upload-text">上传图片</div>
            </div>
            <img v-else :src="forms.coinKindBackIcon" style="width: 100%" />
          </a-upload>
        </a-form-model-item>

        <a-form-model-item label="描述">
          <a-textarea
            v-model="forms.coinKindDesc"
            placeholder="请输入描述"
            auto-size
          />
        </a-form-model-item>

        <a-form-model-item label="排序">
          <a-input placeholder="排序" v-model="forms.coinKindTopSort" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal
      v-model="tagVisible"
      title="新增表签"
      ok-text="确认"
      cancel-text="取消"
      @cancel="cancelTags"
      @ok="addTag"
    >
      <a-form-model
        ref="tagforms"
        :model="tagForms"
        :rules="tagRules"
        v-bind="layout"
      >
        <a-form-model-item label="名称" prop="coinTagName">
          <a-input placeholder="名称" v-model="tagForms.coinTagName" />
        </a-form-model-item>

        <a-form-model-item label="标签分类" prop="coinTagCategory">
          <a-select placeholder="标签分类" v-model="tagForms.coinTagCategory">
            <a-select-option :value="1">别名标签</a-select-option>
            <a-select-option :value="2">钱币性质标签</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal
      v-model="codeVisible"
      title="二维码预览"
      ok-text="下载"
      cancel-text="取消"
      @cancel="cancelCodeVisible"
      @ok="downloadCode"
    >
      <div class="code">
        <img :src="url" alt="" />
      </div>
    </a-modal>

    <!-- 编辑百科 -->
    <a-modal
      v-model="editWikiVisible"
      title="编辑百科"
      ok-text="确定"
      cancel-text="取消"
      :maskClosable="false"
      @cancel="hideWiki"
      @ok="submitWiki"
      :width="700"
    >
      <div class="edit-wiki">
        <div class="utils-box">
          <!-- <a-button
            class="util-item"
            type="primary"
            size="small"
            @click="addLinkModal = true"
            >添加超链接</a-button
          > -->
          <a-upload
             style="margin-right:10px;"
            :action="ip+'/file/upload'"
            :multiple="true"
            :showUploadList='false'
            @change="handleChangeEdit"
          >
            <a-button class="util-item" type="primary" size="small"
              >插入图片</a-button
            >
          </a-upload>
        </div>
        <quillEditor
          ref="myQuillEditor"
          :options="options"
          v-model="wikiContent"
          style="min-height: 200px"
        ></quillEditor>
      </div>
    </a-modal>
    <!-- 预览百科 -->
    <a-modal
      v-model="previewWikiVisible"
      title="预览百科"
      ok-text="确定"
      cancel-text="取消"
      @cancel="hideWikiVisible"
      @ok="hideWikiVisible"
      :width="700"
    >
      <div class="preview-wiki" v-html="currentViki.coinKindContent"></div>
    </a-modal>
    <!-- 添加超链接 -->
    <a-modal
      v-model="addLinkModal"
      title="添加超链接"
      ok-text="确定"
      cancel-text="取消"
      @cancel="hideAddLinkModal"
      @ok="addLink"
    >
      <a-form-model
        ref="addLinkforms"
        :model="addLinkForms"
        :rules="addLinkRules"
        v-bind="layout"
      >
        <a-form-model-item label="名称" prop="name">
          <a-input placeholder="请输入名称" v-model="addLinkForms.name" />
        </a-form-model-item>
        <a-form-model-item label="链接地址" prop="url">
          <a-input placeholder="请输入链接地址" v-model="addLinkForms.url" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
<!--  编辑开放状态  -->
    <BigCoinOpenPopup ref="bigCoinOpenPopupEl" @success="search"/>
<!--  自定义朝代列表  -->
    <CustomDynastyNameListPopup ref="customDynastyNameListPopupEl"/>
  </div>
</template>

<script>
import { mapState } from "vuex";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import quillConfig from "@/untils/qullsample.config";
import untils from "@/untils"
import {openStatusClassType, openStatusList, openStatusText} from "@/views/cmsPage/currencyMannage/_data"
import BigCoinOpenPopup from "@/views/cmsPage/currencyMannage/_components/BigCoinOpenPopup"
import CustomDynastyNameListPopup from "@/views/cmsPage/currencyMannage/_components/CustomDynastyNameListPopup"
export default {
  data() {
    return {
      openStatusList,
      openStatusText,
      openStatusClassType,
      untils,
      //百科----
      options: quillConfig,
      wikiContent: "",
      editWikiVisible: false,
      currentViki: "",
      previewWikiVisible: false,
      addLinkModal: false, //超连接
      addLinkForms: {},
      addLinkRules: {
        name: [
          { required: true, message: "please change", trigger: "change" },
        ],
        url: [
          { required: true, message: "please change", trigger: "change" },
        ],
      },
      //----百科
      url: "",
      codeVisible: false,
      checked: "",
      columns: [
        { title: "ID", dataIndex: "id" },
        {
          title: "钱币名称",
          scopedSlots: { customRender: "nameSetup" },
          align: "center",
        },
        {title:'正面图',scopedSlots:{customRender:'Images'}},
        {title:'背面图', dataIndex: "coinKindBackIcon" ,scopedSlots:{customRender:'backImages'}},
        { title: "类型", dataIndex: "coinCategory" },
        { title: "分类类型", scopedSlots: { customRender: "dynastyTypeSlot" }, width: 250 },
        { title: "皇帝", dataIndex: "rulerName" },
        { title: "标签", scopedSlots: { customRender: "tags" } },
        {
          title: "二维码",
          align: "center",
          scopedSlots: { customRender: "twoSetup" },
        },
        {
          title: "开放对版状态",
          align: "center",
          scopedSlots: { customRender: "openStatus" },
        },
        {
          title: "操作",
          align: "center",
          width: 250,
          scopedSlots: { customRender: "setup" },
        },
      ],
      data: [],
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      imgKey: "",
      forms: {}, //大币种
      tagForms: {},
      loading: false,
      tagRules: {
        coinTagCategory: [
          { required: true, message: "please change", trigger: "blur" },
        ],
        coinTagName: [
          { required: true, message: "please change", trigger: "change" },
        ],
      },
      rules: {
        coinCategorySid: [{ required: true, message: "please change", trigger: "change" },],
        coinKindName: [{ required: true, message: "please enter name", trigger: "blur" },],
        tag: [{ required: true, message: "please enter a tag", trigger: "change" },],
        dynastyId: [{required: true, message: "please change dynasty", trigger: "change",},],
        dynastyRulerId: [{required: false, message: "please change emperor", trigger: "change",},],
      },
      visible: false,
      tagVisible: false,
      innerTitle: "",
      dynasty_list: [],
      classify_list: [],
      emperor_list: [],
      fileList: [],
      currency_type_list: [],
      IO: 0,
      tagCurrent: "",
      pagination: {
        total: 0,
        current: 1,
        showTotal: (total) => `共${total}条`,
      },
      searchParams: {
        tags: "",
        isCreate: 2,
        pageNum: 1,
        pageSize: 10,
        openStatus: undefined
      },
      kingListData: [],
      kingParams:{
        limit:10,
        page:1,
        dynastyName:'',
        rulerName:'',
        rulerDynastyName:'',
      },
      searchDynastyParams: {
        pageNum: 1,
        pageSize: 100,
        type: undefined,
        dynastyName: undefined
      },
      tempDynastyClassifyIdList: [],
      search_classify_list: [],
      search_dynasty_list: []
    };
  },
  computed: {
    ...mapState(["ip"]),
  },
  watch: {
    visible() {
      this.imgKey = this.visible ? "" : Math.random();
    },
  },
  components: {
    quillEditor,
    BigCoinOpenPopup,
    CustomDynastyNameListPopup,
  },
  async created() {
    await this.getData(this.searchParams);
    await this.getCurrencyTypeList()
    await this.getDynastyList('', 'dynasty', 'search')
    await this.getDynastyList('', 'classify', 'search')
  },
  methods: {
    /** 获取朝代--朝代列表 */
    async getDynastyList(searchStr, type, searchType) {
      this.searchDynastyParams.dynastyName = searchStr
      this.searchDynastyParams.type = type === 'dynasty' ? 0 : 1
      await this.axios("/dq_admin/dynasty/newlist", {
        params: {
          ...this.searchDynastyParams,
        }
      }).then((res) => {
        const { records } = res.data;
        if (type === 'dynasty') {
          this.dynasty_list = records;
          if (searchType) {
            this.search_dynasty_list = records;
          }
        } else {
          this.classify_list = records
          if (searchType) {
            this.search_classify_list = records;
          }
        }
      });
    },
    /** 获取分类列表 */
    async getCurrencyTypeList() {
      await this.axios("/dq_admin/category/list").then((res) => {
        const { records } = res.data;
        this.currency_type_list = records || [];
      });
    },
    /** 自定义朝代分类列表 */
    handleShowDuiBanCustomCategoryList() {
      this.$refs.customDynastyNameListPopupEl.show()
    },
    /** 获取皇帝 */
    async getKingListData(value) {
      this.kingParams.rulerName = value
      const res = await this.axios('/dq_admin/ruler/list',{params: this.kingParams})
      this.loading = false
      const { records } = res.data
      this.kingListData = records
    },
    // 上传反面图
    coinKindBackIconChange(res, form) {
      try {
        const { response } = res.file;
        if (response.data) {
          const { data } = response;
          if (data) {
            this.$set(this.forms, "coinKindBackIcon", data);
          }
        }
      } catch (e) {}
    },
     // 上传正面图
    coinKindIconChange(res, form) {
      try {
        const { response } = res.file;
        if (response.data) {
          const { data } = response;
          if (data) {
            this.$set(this.forms, "coinKindIcon", data);
          }
        }
      } catch (e) {}
    },
    // 百科
    // 提交数据
    async submitWiki() {
      // 数据处理
      this.calcWikiContent()
      const res = await this.axios.post("/dq_admin/kind/editWiki", {
        id: this.currentViki.id,
        coinKindContent: this.wikiContent,
      });
      this.$message.success(res.message);
      if (res.status != "200") return;
      this.editWikiVisible = false;
      await this.getData(this.searchParams);
    },
    // 处理数据
    calcWikiContent(){
      this.wikiContent = `<div style="white-space:pre-wrap;">${this.wikiContent}</div>`
      this.wikiContent = this.wikiContent.replace(/class="ql-align-center"/g, "style='text-align:center'")
      this.wikiContent = this.wikiContent.replace(/<img src/g, "<img style='width:80%' src")
      this.wikiContent = this.wikiContent.replace(/<a[^>]*>/g, "")
      this.wikiContent = this.wikiContent.replace(/<*\/a>/g, "")
    },
    editWikiClick(row) {
      this.currentViki = row;
      this.editWikiVisible = true;
      this.wikiContent = this.currentViki.coinKindContent;
    },
    hideWiki() {
      this.editWikiVisible = false;
      this.wikiContent = "";
    },
    // 插入图片
    handleChangeEdit(row){
        const {data} = row.file.response||{data:null}
        if (data) {
          this.wikiContent = this.wikiContent + `<p style="text-align:center;"><img style="width: 80%" src="${data}" /></p><p></p>`
          this.wikiContent = `<div style="white-space:pre-wrap;">${this.wikiContent}</div>`
        }
    },
    // 查看百科
    // 显示
    previewWikiClick(row) {
      this.currentViki = row;
      this.previewWikiVisible = true;
    },
    // 隐藏
    hideWikiVisible() {
      this.previewWikiVisible = false;
    },
    // 超链接
    hideAddLinkModal(){
        this.addLinkModal = false
    },
    // 插入超链接
    addLink(){
        this.$refs.addLinkforms.validate((valid) => {
        if (valid) {
          this.wikiContent = this.wikiContent.slice(0, this.wikiContent.length - 4) + 
            `<a href="${this.addLinkForms.url}">${this.addLinkForms.name}</a>` +
            `</p>`
          this.addLinkModal = false;
          this.addLinkForms = {}
        } else {
          return false;
        }
      });
    },
    /** 编辑对版是否开放 */
    handleShowChangeOpenCoin(row) {
      const { id, coinKindName, duibanCoin } = row
      this.$refs.bigCoinOpenPopupEl.showPopup(id, coinKindName, duibanCoin)
    },
    // ---百科
    async openModal(row) {
      this.tempDynastyClassifyIdList = []
      const { id } = row;
      const type = id ? 1 : 0;
      this.IO = type;
      this.visible = true;
      const text = type == 0 ? "新增大币种" : "编辑大币种";
      this.innerTitle = text;
      this.forms = id
        ? JSON.parse(JSON.stringify(row))
        : { tagList: [], dynastyRulerId: [] };
      if (row && row.dynastyClassifyIds && row.coinCategoryId === 5) {
        this.tempDynastyClassifyIdList = row.dynastyClassifyList.map(el => el.id)
      }
      if (row && row.dynastyId && row.coinCategoryId !== 5) {
        const result = this.dynasty_list.filter((m) => m.id === row.dynastyId);

        if (result && result.length > 0) {
          result[0].rulerList.map((v) => (v.id = v.id.toString()));
          this.emperor_list = result[0].rulerList;
        }
        if (this.forms.dynastyRulerId) {
          this.forms.dynastyRulerId = [this.forms.dynastyRulerId.toString()];
        } else {
          this.forms.dynastyRulerId = [];
        }
        await this.getDynastyList(row.dynastyName, 'dynasty')
      } else {
        this.forms.dynastyRulerId = [];
        this.emperor_list = [];
      }
    },
    hideModal() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.forms.coinCategorySid === '6ed643eb-8842-4b' && !this.tempDynastyClassifyIdList.length) return this.$message.warn('请选择花钱分类')
          if (this.tempDynastyClassifyIdList.length) {
            this.forms.dynastyClassifyIds = this.tempDynastyClassifyIdList.join(',')
          }
          this.submit(this.forms);
        } else {
          return false;
        }
      });
    },
    submit(forms) {
      const copy_json = JSON.parse(JSON.stringify(forms));
      if (Array.isArray(copy_json.dynastyRulerId)) {
        copy_json.dynastyRulerId = copy_json.dynastyRulerId.join(",");
      }
      const post_url =
        this.IO == 1 ? "/dq_admin/kind/edit" : "/dq_admin/kind/add";
      this.axios.post(post_url, copy_json).then((res) => {
        if (res.status == "SUCCESS") {
          this.$message.success(res.message);
          this.fileList = [];
          this.visible = false;
          this.getData(this.searchParams);
        }
      });
    },
    changeDynasty(id, node) {
      //切换朝代
      const { childs } = node.data.attrs;
      childs.map((v) => (v.id = v.id.toString()));
      this.forms.dynastyRulerId = [];
      this.emperor_list = childs || [];
    },
    async getData(params) {
      this.loading = true;
      const res = await this.axios("/dq_admin/kind/list", { params: params });
      this.loading = false;
      const { records, total } = res.data;
      this.data = records;
      this.pagination.total = total;
    },
    search() {
      this.searchParams.pageNum = 1;
      this.getData(this.searchParams);
    },
    async remove(params) {
      const res = await this.axios("/dq_admin/kind/delKindById", {
        params: { id: params },
      });
      if (res.status == "SUCCESS") {
        this.$message.success(res.message);
        await this.getData(this.searchParams);
      }
    },
    addTag() {
      this.$refs.tagforms.validate((valid) => {
        if (valid) {
          if (typeof this.tagCurrent == "number") {
            this.forms.tagList.splice(this.tagCurrent, 1, this.tagForms);
          } else {
            this.forms.tagList.push(this.tagForms);
          }
          this.tagVisible = false;
        } else {
          return false;
        }
      });
    },
    confirmTag(row, index) {
      this.tagVisible = true;
      this.tagForms = JSON.parse(JSON.stringify(row));
      this.tagCurrent = index || index === 0 ? index : "";
    },
    removeTag(n) {
      this.forms.tagList.splice(n, 1);
    },
    cancelTags() {
      this.tagForms = {};
      this.$refs.tagforms.resetFields();
    },
    resetForms() {
      this.forms = {};
      this.fileList = [];
      this.$refs.ruleForm.resetFields();
    },
    uploadStateChange(res,tyype) {
      try {
        //当前为异步操作，为防止控制台报错
        this.fileList = res.fileList;
        const { data } = res.file.response;
        this.forms.coinKindIcon = data;
      } catch (e) {}
    },
    changePage(page) {
      const { current, pageSize } = page;
      this.pagination.current = current;
      this.searchParams.pageNum = current;
      this.searchParams.pageSize = pageSize;
      this.getData(this.searchParams);
    },
    changeSelect(sid) {
      if (sid && sid.length > 1) {
        sid.length = 1;
      }
      if (sid && sid.length == 1) {
        const result = this.replaceSid(
          this.emperor_list,
          "rulerName",
          sid.join(",")
        );
        sid = result && result.length > 1 ? [result[0].id] : sid;
        this.forms.dynastyRulerId = sid;
      }
    },
    replaceSid(array, key, name) {
      const result = array.filter((item) => item[key] == name);
      return result;
    },
    onChange(e) {
      this.searchParams.pageNum = 1;
      this.checked = e.target.checked;
      if (e.target.checked == true) {
        this.searchParams.isCreate = 1;
        this.getData(this.searchParams);
      } else {
        this.searchParams.isCreate = 2;
        this.getData(this.searchParams);
      }
    },
    lookCOde(row) {
      this.url = row;
      this.codeVisible = true;
    },
    makeCode(row) {
      this.axios("/dq_admin/kind/createCodeBySid", {
        params: { sid: row.sid },
      }).then((res) => {
        if (res.status == "SUCCESS") {
          this.$message.success("生成成功");
          this.getData(this.searchParams);
        }
      });
    },
    cancelCodeVisible() {
      this.codeVisible = false;
    },
    downloadCode() {
      var alink = document.createElement("a");
      alink.href = this.url + "?response-content-type=application/octet-stream";
      alink.download = "pic"; //图片名
      alink.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.search-attr {
  display: flex;
  justify-content: space-between;
}
.content {
  display: flex;
  flex-direction: column;
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}
.code {
  display: flex;
  justify-content: center;
}
.codeText {
  color: #1890ff;
  cursor: pointer;
}
.utils-box {
  display: flex;
  .util-item {
    font-size: 12px;
    margin-right: 4px;
    margin-bottom: 4px;
  }
}
::v-deep .ql-container{
  max-height: 300px !important;
  overflow-y: scroll !important;
}
.preview-wiki{
  max-height: 360px;
  overflow-y: scroll;
}
</style>